const LogoSliderMobile = (function ($) {
  function init() {
    LogoSliderHandle();
  }

  const LogoSliderHandle = () => {
    const swiper = new Swiper(".swiper", {
      speed: 400,
      spaceBetween: 100,
      autoplay: true,
      slidesPerView: 1,
      loop: true,
    });
  };

  return {
    init: init,
  };
})(jQuery);

jQuery("document").ready(function () {
  LogoSliderMobile.init();
});
