import Player from "@vimeo/player";
import InView from "@opuu/inview";

const VimeoPlayer = (function ($) {
  function init() {
    VimeoPlayer();
  }

  const VimeoPlayer = () => {
    const vimeoElements = document.querySelectorAll("[data-vimeoId]");

    const watcher = new InView(`.aformat-video-vimeo`);

    watcher.on("enter", (event) => {
      vimeoElements.length > 0 &&
        vimeoElements.forEach(function (element) {
          if (element.id === event.target.id) {
            const vimeoPlayer = new Player(element, {
              id: element.dataset.vimeoid,
              background: true,
              loop: true,
              dnt: true,
            });

            vimeoPlayer.on("play", function () {
              if (!element.parentElement.getElementsByTagName("img")[0]) return;

              element.parentElement.getElementsByTagName(
                "img"
              )[0].style.transition = "all ease 300ms";

              element.parentElement.getElementsByTagName(
                "img"
              )[0].style.opacity = 0;
            });
          }
        });
    });
  };

  return {
    init: init,
  };
})(jQuery);

jQuery("document").ready(function () {
  VimeoPlayer.init();
});
