import Sticky from "sticky-js";

const SctickyHandle = (function ($) {
  function init() {
    StickyHandle();
  }

  const StickyHandle = () => {
    if (window.innerWidth > 1023) {
      new Sticky(".sticky");
    }
  };

  return {
    init: init,
  };
})(jQuery);

jQuery("document").ready(function () {
  SctickyHandle.init();
});
