import SimpleParallax from "simple-parallax-js";
import Sticky from 'sticky-js';

const ImageParallax = (function ($) {
  function init() {
    ImageParallaxHandle();
    ImageParallaxMovement();
  }

  const ImageParallaxHandle = () => {
    const images = document.querySelectorAll(".is-parallax");

    new SimpleParallax(images, {
      delay: 0,
      scale: 1.25,
      transition: "cubic-bezier(0,0,0,1)",
      maxTransition: 100,
      orientation: "down",
    });
  };

  const ImageParallaxMovement = () => {
    const Elements = document.querySelectorAll("[data-movement]");
    if (window.innerWidth > 1023) {
      $(window).on("scroll", function () {
        Elements.length > 0 &&
          Elements.forEach(function (element) {
            if (element.dataset.movement !== "null") {
              if (
                element.dataset.movement === "down" &&
                element.offsetTop - 200 < document.documentElement.scrollTop
              ) {
                const valueY =
                  (document.documentElement.scrollTop -
                    (element.offsetTop - 200)) *
                  0.3;
                element.style.transform = `translateY(${valueY}px)`;
              }

              if (
                element.dataset.movement === "up" &&
                element.offsetTop - 500 < document.documentElement.scrollTop
              ) {
                const valueY =
                  (document.documentElement.scrollTop -
                    (element.offsetTop - 500)) *
                  0.3;
                element.style.transform = `translateY(-${valueY}px)`;
              }
            }
          });
      });
    }
  };

  return {
    init: init,
  };
})(jQuery);

jQuery("document").ready(function () {
  ImageParallax.init();
});
